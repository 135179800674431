import { css } from '@emotion/react';
import globalStyle from '../../../../shared/global.style';

const cameraScan = css`
  flex: 1;
  width: 100%;
  color: white;
  background-color: black;
  text-align: center;
`;

const cameraContainer = css`
  height: 100%;
  min-height: 300px;
  min-width: 320px;
  background-color: var(--dcd-black-color);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const video = css`
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
`;

const targetSquareMessage = css`
  ${globalStyle.bodyNormal};
  color: var(--dcd-black-color);
  background-color: var(--dcd-secondary-color-40);
  width: 100%;
  top: 20px;
  text-align: center;
  padding: 10px;
`;

const barcodeSquareContainer = css`
  position: absolute;
  left: 0;
  right: 0;
`;

const corner = css`
  position: absolute;
  width: 10%;
  height: 15%;
  border-color: var(--dcd-white-color);
  border-style: solid;
  content: ' ';
`;

const corners = css`
  :before,
  :after {
    ${corner};
    bottom: 0;
  }
  :before {
    right: 0;
    border-width: 0 2px 2px 0;
  }

  :after {
    left: 0;
    border-width: 0 0 2px 2px;
  }
`;
const targetSquare = css`
  position: relative;
  width: 80%;
  height: 150px;
  padding: 25px 30px;
  margin: 0 auto;

  :before,
  :after {
    ${corner};
    top: 0;
  }

  :before {
    left: 0;
    border-width: 2px 0 0 2px;
  }

  :after {
    right: 0;
    border-width: 2px 2px 0 0;
  }
`;
export default {
  cameraScan,
  cameraContainer,
  targetSquareMessage,
  video,
  barcodeSquareContainer,
  targetSquare,
  corners,
};
