/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Icon, Image, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useAppDispatch, useAppSelector } from '../../../../core/hooks';
import {
  fetchEmployeeCard,
  selectEmployeeCard,
  selectEmployeeCardInitials,
  selectIsFetchingEmployeeCard,
} from '../store/employeeDiscountCardSlice';
import style from './employeeCard.style';
import { emptyBasket } from '../../products/store/basketSlice';
import { resetProductSearch } from '../../products/store/productSearchSlice';
import QrCodeModal from '../../../../shared/components/QrCodeModal';
import { getThemedLogo, isGucciBrandCode } from '../../../../shared/models/brand';
import { fetchSpecialCases } from '../../../admin/discounts/store/specialCasesSlice';
import { DetailAction, EmployeeCardActionsModal } from '../../../../shared/components/EmployeeCardActionsModal';
import Header from '../../../../shared/components/Header';
import { selectSelectedStore } from '../store/salesAssistantSlice';

export const EmployeeDiscountCard = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const employeeCard = useAppSelector(selectEmployeeCard);
  const employeeInitials = useAppSelector(selectEmployeeCardInitials);
  const isFetching = useAppSelector(selectIsFetchingEmployeeCard);
  const [actionsModalOpen, setActionsModalOpen] = useState(false);
  const actions: DetailAction[] = ['exportPdf'];
  const employeeCardRef = useRef(null);
  const selectedStore = useAppSelector(selectSelectedStore);

  useEffect(() => {
    if (id != null) {
      dispatch(fetchEmployeeCard(id));
      dispatch(emptyBasket());
      dispatch(resetProductSearch());
      if (employeeCard.isSpecialCaseGoldCard) {
        dispatch(fetchSpecialCases());
      }
    }
  }, [dispatch, employeeCard.isSpecialCaseGoldCard, id]);

  const [modalOpen, setModalOpen] = useState(false);

  const handleCardPdfDownload = async () => {
    if (employeeCardRef.current != null) {
      try {
        const canvas = await html2canvas(employeeCardRef.current, { allowTaint: true, useCORS: true, scale: 1.5 });
        const imgData = canvas.toDataURL('image/svg');
        // eslint-disable-next-line new-cap
        const pdf = new jsPDF();
        const pageWidth = pdf.internal.pageSize.getWidth();
        const imgWidth = 90;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const x = (pageWidth - imgWidth) / 2;
        const y = 10;
        pdf.addImage(imgData, 'SVG', x, y, imgWidth, imgHeight);
        pdf.save(`employee_card_${employeeCard.givenName}_${employeeCard.surname}`);
      } catch (error) {
        console.error('Error generating PDF:', error);
      }
    }
  };

  return (
    <div css={style.container}>
      <div css={style.headerContainer}>
        <Header
          redirectPath='/privilege-card/sales-assistant/card-search'
          title={t('salesAssistant.cardSearch.employeeCard')}
        />
      </div>
      <div css={style.contentContainer}>
        {isFetching ? (
          <Loader size='big' active />
        ) : (
          <>
            {(employeeCard?.isBlocked === true || employeeCard?.isEligibleUser === false) && (
              <>
                <Icon name='lock' size='huge' css={style.lockIcon} />
                <div css={style.blockedCardMessage}>
                  <strong>{t('salesAssistant.cardSearch.blockedCardMessage')}</strong>
                </div>
              </>
            )}
            <div css={style.wrapper}>
              <Button onClick={() => setActionsModalOpen(true)} css={style.downloadButton}>
                <Icon name='download' css={style.downloadIcon} size='big' />
              </Button>
              <div
                ref={employeeCardRef}
                css={style.cardBackground(
                  employeeCard.brand.code,
                  employeeCard.isBlocked,
                  employeeCard.isSpecialCaseGoldCard,
                  employeeCard.isSpecialCaseSilverCard,
                  employeeCard?.isEligibleUser
                )}>
                <div css={style.content}>
                  <Image
                    src={getThemedLogo(
                      employeeCard.brand.code,
                      employeeCard.isSpecialCaseGoldCard,
                      employeeCard.isSpecialCaseSilverCard
                    )}
                    size='small'
                  />
                  {employeeCard.photo === 'no-image' ? (
                    <div css={style.employeeInitials}>{employeeInitials}</div>
                  ) : (
                    <div css={style.circularImage}>
                      <Image src={employeeCard.photo} size='small' centered />
                    </div>
                  )}
                  <div css={style.nameSection}>
                    <div css={style.name}>{`${employeeCard.givenName} ${employeeCard.surname}`}</div>
                  </div>
                  <div css={style.emailSection}>
                    {employeeCard.workdayId != null && (
                      <div css={style.info}>
                        {t('myPersonalInformation.workdayId')} - {employeeCard.workdayId}
                      </div>
                    )}
                    <div css={style.info}>{employeeCard.mail}</div>
                    <div css={style.info}>{employeeCard.country.toUpperCase()}</div>
                  </div>
                  <div
                    css={style.qrCode}
                    onClick={() =>
                      employeeCard.isBlocked || employeeCard?.isEligibleUser === false ? null : setModalOpen(true)
                    }>
                    <Image src={employeeCard.qrCode} />
                  </div>
                </div>
              </div>
            </div>
            <div css={style.buttonsContainer}>
              <Button
                css={style.cardSearchPageButtons}
                content={
                  isGucciBrandCode(selectedStore?.brand.code)
                    ? t('salesAssistant.cardSearch.scanProduct')
                    : t('salesAssistant.cardSearch.searchProduct')
                }
                onClick={() => navigate('/privilege-card/sales-assistant/product-search')}
                disabled={employeeCard.isBlocked || employeeCard?.isEligibleUser === false}
              />
              <Button
                css={style.cardSearchPageButtons}
                content={t('salesAssistant.cardSearch.checkQuota')}
                onClick={() => navigate('/privilege-card/sales-assistant/check-quota')}
                disabled={employeeCard.isBlocked || employeeCard?.isEligibleUser === false}
              />
            </div>
          </>
        )}
      </div>

      <div>
        <EmployeeCardActionsModal
          onCancel={() => setActionsModalOpen(false)}
          open={actionsModalOpen}
          downloadPdf={handleCardPdfDownload}
          addToAppleWallet={() => console.log('add to apple wallet')} // fixme remove log
          shareContact={() => ''}
          actions={actions}
        />
        <QrCodeModal onClose={() => setModalOpen(false)} image={employeeCard.qrCode} open={modalOpen} />
      </div>
    </div>
  );
};
