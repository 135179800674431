/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next';
import { Button, Card, Dimmer, Icon, Image, Loader } from 'semantic-ui-react';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector, useParamSelector } from '../../../../core/hooks';
import { selectEmployeeCard } from '../../cardSearch/store/employeeDiscountCardSlice';
import { toLocaleAmount } from '../../../../shared/utils/utils';
import style from './productDetail.style';
import { selectSelectedStore } from '../../cardSearch/store/salesAssistantSlice';
import { BasketProduct } from '../models/basket';
import { selectBasketProductBySku, selectBasketProductCountBySku } from '../store/basketSlice';
import { useBasket } from '../utils/useBasket';
import { SalesAssistantStore } from '../../../auth/models/principal';
import { Product } from '../models/product';
import { isGucciBrandCode } from '../../../../shared/models/brand';
import { selectIsFetchingProductImage, selectProductImage } from '../store/productImageSlice';
import { toastService } from '../../../../core/services/toastService';

export const ProductDetail = ({
  selectedProduct,
  setIsProductSelected,
}: {
  selectedProduct: Product;
  setIsProductSelected: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
  const { t } = useTranslation();

  const employeeCard = useAppSelector(selectEmployeeCard);
  const selectedSalesAssistantStore = useAppSelector(selectSelectedStore) as SalesAssistantStore;
  const productImage = useAppSelector(selectProductImage);
  const isFetchingProductImage = useAppSelector(selectIsFetchingProductImage);

  const discountedPrice: number | null = useMemo(() => {
    if (employeeCard.workdayId !== '' && selectedProduct.price != null && selectedProduct.discount != null) {
      return (selectedProduct.price * (100 - selectedProduct.discount)) / 100;
    }
    return null;
  }, [employeeCard.workdayId, selectedProduct.price, selectedProduct.discount]);

  return (
    <div css={style.productDetailContainer}>
      {employeeCard.mail === '' && (
        <Dimmer active>
          {t('salesAssistant.productsSearch.pleaseSelectCard')} {t('salesAssistant.productsSearch.click')}{' '}
          <Link to='/privilege-card/sales-assistant/card-search'>{t('salesAssistant.productsSearch.here')}</Link>{' '}
          {t('salesAssistant.productsSearch.toRedirect')}
        </Dimmer>
      )}
      <div css={style.scrollableCard}>
        <div css={style.cardContainer}>
          <Card css={style.card}>
            {isFetchingProductImage ? (
              <div css={style.loaderContainer}>
                <Loader size='medium' active inline />
              </div>
            ) : productImage === 'no-image' ? (
              <div css={style.noImageText}>{t('salesAssistant.productDetail.noImage')}</div>
            ) : (
              <Image css={style.itemImage} centered src={productImage} />
            )}
            <Card.Content css={style.cardContent}>
              {selectedProduct.isEligible === false && (
                <div css={style.notEligible}>
                  <Icon name='times circle' size='small' css={style.notEligibleIcon} />
                  {t('salesAssistant.productDetail.notEligible')}
                </div>
              )}
              <div css={style.productInfo}>
                <div css={style.category}>
                  {selectedProduct.category}
                  {selectedProduct.brandNumericCode === '51' &&
                    selectedProduct.subCategory != null &&
                    ` - ${selectedProduct.subCategory}`}
                </div>
                <div css={style.name}>{selectedProduct.name}</div>
                <div css={style.description}>
                  {selectedProduct.sku} - {selectedProduct.description}
                </div>
              </div>
              {selectedProduct.isEligible === true && selectedProduct.discount != null && selectedProduct.discount > 0 && (
                <div>
                  {selectedProduct.discount}% {t('salesAssistant.productDetail.discountApplicable')}
                </div>
              )}
              <ProductPrice
                hideDiscount={selectedProduct.isEligible !== true || selectedProduct.discount === 0}
                discountedPrice={discountedPrice}
                price={selectedProduct.price}
                priceCurrency={selectedProduct.priceCurrency}
              />
              {isGucciBrandCode(selectedSalesAssistantStore?.brand.code) && (
                <BasketProductActions
                  product={selectedProduct}
                  isEligible={selectedProduct.isEligible === true}
                  setIsProductSelected={setIsProductSelected}
                />
              )}
            </Card.Content>
          </Card>
        </div>
      </div>
    </div>
  );
};

const ProductPrice = ({
  price,
  priceCurrency,
  discountedPrice,
  hideDiscount,
}: {
  hideDiscount: boolean;
  discountedPrice: number | null;
  price: number | undefined;
  priceCurrency: string | undefined;
}): JSX.Element => {
  return priceCurrency != null ? (
    <div>
      {discountedPrice == null || hideDiscount ? (
        <span css={style.currentPrice}>
          {price != null && toLocaleAmount(Math.round(price))}
          {priceCurrency}
        </span>
      ) : (
        <>
          <span css={style.currentPrice}>
            {toLocaleAmount(Math.round(discountedPrice))}
            {priceCurrency}
          </span>
          <span css={style.originalPrice}>
            {price != null && toLocaleAmount(Math.round(price))}
            {priceCurrency}
          </span>
        </>
      )}
    </div>
  ) : (
    <div />
  );
};

const BasketProductActions = ({
  product,
  isEligible,
  setIsProductSelected,
}: {
  product: Product;
  isEligible: boolean;
  setIsProductSelected: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const employeeCard = useAppSelector(selectEmployeeCard);
  const selectedStore = useAppSelector(selectSelectedStore) as SalesAssistantStore;
  const basketProduct: BasketProduct = useParamSelector(selectBasketProductBySku, product.sku);
  const initialProductCounter: number = useParamSelector(selectBasketProductCountBySku, product.sku);
  const { handleBasketProductAction, basketActionLabel, decrementCounter, incrementCounter, productCounter } =
    useBasket(employeeCard, selectedStore, basketProduct, product, initialProductCounter);

  return (
    <div css={style.basketActions}>
      <div css={style.counterContainer}>
        <Button css={style.counterButton} icon='minus' onClick={decrementCounter} disabled={!isEligible} />
        <div css={style.counterValue}>{productCounter}</div>
        <Button css={style.counterButton} icon='plus' onClick={incrementCounter} disabled={!isEligible} />
      </div>
      <Button
        css={style.basketButtonAction}
        content={basketActionLabel}
        onClick={() => {
          setIsProductSelected(false);
          handleBasketProductAction();
          setTimeout(() => {
            toastService.success();
          }, 1000);
        }}
        disabled={!isEligible}
      />
    </div>
  );
};
