import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk, RootState } from '../../../../core/store';
import api from '../utils/api';
import { ProductLsku } from '../models/productLsku';

interface ProductLskusSliceState {
  data: ProductLsku[] | null;
  isFetching: boolean;
  error: string;
}

const initialState: ProductLskusSliceState = {
  data: null,
  isFetching: false,
  error: '',
};

export const productLskusSlice = createSlice({
  name: 'productLskus',
  initialState,
  reducers: {
    startFetch: (state: Draft<ProductLskusSliceState>) => ({ ...state, isFetching: true }),
    finishFetch: (state: Draft<ProductLskusSliceState>, action: PayloadAction<ProductLsku[]>) => {
      return { isFetching: false, data: [...action.payload], error: '' };
    },
    httpError: (state: Draft<ProductLskusSliceState>, action: PayloadAction<string>) => ({
      ...state,
      isFetching: false,
      error: action.payload,
    }),
  },
});

export const { startFetch, finishFetch, httpError } = productLskusSlice.actions;

export const fetchProductLskus =
  (brandCode: string): AppThunk =>
  async dispatch => {
    dispatch(startFetch());
    try {
      const productLskus = await api.getProductLskusByBrand(brandCode);
      dispatch(finishFetch(productLskus));
    } catch (error) {
      dispatch(httpError(JSON.stringify(error)));
    }
  };

export const selectProductLskus = (state: RootState): string[] =>
  state.productLskus.data?.map(product => product.lsku) || [];

export const selectIsFetchingLskus = (state: RootState): boolean => state.productLskus.isFetching;

export default productLskusSlice.reducer;