/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, DropdownItemProps, DropdownProps, Icon, Input, SemanticICONS } from 'semantic-ui-react';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { addYears } from 'date-fns'; // todo check
import moment from 'moment';
import { FormField } from '../../../../../shared/components/FormField';
import { Constraints, LimitRule, Limits, MoneyAmount } from '../../models/limitRule';
import style from './limitRuleForm.style';
import { Brand } from '../../../../../shared/models/brand';
import { Country } from '../../../../../shared/models/country';
import { useAppDispatch, useAppSelector } from '../../../../../core/hooks';
import { selectSortedPrincipalBrands } from '../../../../auth/store/principalSlice';

import { fetchCountries, selectIsFetchingCountries, selectSortedCountries } from '../../stores/countriesSlice';
import {
  fetchProductCategories,
  selectIsFetchingProductCategories,
  selectSortedProductCategories,
} from '../../stores/productCategoriesSlice';
import { fetchProductSkus, selectIsFetchingSkus, selectProductSkus } from '../../stores/productSkusSlice';
import { LimitType } from '../../stores/limitRulesSlice';
import ItemsCountField from './ItemsCountField';
import { selectEmployeeTypes } from '../../../../../shared/store/employeeTypesSlice';
import { createRule, updateRule } from '../../stores/limitRuleDetailSlice';
import AmountField from './AmountField';
import {
  fetchProductStyleLines,
  selectIsFetchingStyleLines,
  selectSortedProductStyleLines,
} from '../../stores/productStyleLinesSlice';
import { NonEligibleBulkUploadModal } from './NonEligibleBulkUploadModal';
import {
  fetchNonEligibleConstraints,
  selectIsFetchingNonEligibleConstraints,
  selectNonEligibleConstraints,
} from '../../stores/nonEligibleConstraintsSlice';
import { NonEligibleConstraint } from '../../models/nonEligibleConstraint';
import { ProductSku } from '../../models/productSku';
import { storeTypologiesArray } from '../../../../../shared/models/storeTypology';
// import { fetchProductLskus, selectIsFetchingLskus, selectProductLskus } from '../../stores/productLskusSlice';

export default function LimitRuleForm({
  limitRule,
  ruleType,
}: {
  limitRule: LimitRule | null;
  ruleType: LimitType;
}): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [maxQuantity, setMaxQuantity] = useState(limitRule?.limits.totalItemsCount || null);
  const [maxQuantityPerSku, setMaxQuantityPerSku] = useState(limitRule?.limits.itemsCountPerSku || null);
  const [maxAmount, setMaxAmount] = useState(limitRule?.limits.amount || null);
  const [constraints, setConstraints] = useState<Partial<Constraints> | null>(limitRule?.constraints || null);
  const [showValidation, setShowValidation] = useState(false);

  const isValidLimit = useMemo(() => {
    switch (ruleType) {
      case 'maxAmount':
        return maxAmount != null && maxAmount.value > 0;
      case 'maxItems':
        return maxQuantity != null && maxQuantity > 0;
      case 'maxItemsBySku':
        return maxQuantityPerSku != null && maxQuantityPerSku > 0;
      case 'notEligible':
        return true;
      default:
        return false;
    }
  }, [maxAmount, maxQuantity, maxQuantityPerSku, ruleType]);

  useEffect(() => {
    if (limitRule != null) {
      setMaxAmount(limitRule.limits.amount);
      setMaxQuantity(limitRule.limits.totalItemsCount);
      setMaxQuantityPerSku(limitRule.limits.itemsCountPerSku);
      setMaxAmount(limitRule.limits.amount);
      setConstraints(limitRule.constraints);
    }
  }, [limitRule]);

  const saveLimitRule = useCallback(async () => {
    setShowValidation(true);
    if (
      !(
        constraints?.brand != null &&
        constraints?.employeeTypes != null &&
        constraints?.employeeTypes.length > 0 &&
        (ruleType === 'notEligible' ? (constraints?.nonEligibleConstraint != null) : true) &&
        isValidLimit
      )
    ) {
      return;
    }
    let result;
    const constraintsToSave: Constraints = {
      brand: constraints?.brand,
      employeeTypes: constraints?.employeeTypes,
      countries: constraints?.countries ?? null,
      storeTypology: constraints?.storeTypology?? null,
      productCategories: constraints?.productCategories ?? null,
      skus: constraints?.skus ?? null,
      nonEligibleConstraint: constraints?.nonEligibleConstraint ?? null,
      validityStartDate: moment(constraints?.validityStartDate).startOf('day').toDate() ?? null,
      validityEndDate: moment(constraints?.validityEndDate).endOf('day').toDate() ?? null,
      categoryLabel: constraints?.categoryLabel || '',
      categoryIcon: constraints?.categoryIcon || '',
    };
    let limitsToSave: Limits = {
      amount: maxAmount,
      totalItemsCount: maxQuantity,
      itemsCountPerSku: maxQuantityPerSku,
    };
    if (ruleType === 'notEligible') {
      limitsToSave = {
        amount: null,
        totalItemsCount: 0,
        itemsCountPerSku: 0,
      };
    }
    if (id != null) {
      result = await dispatch(
        updateRule(id, {
          limits: limitsToSave,
          constraints: constraintsToSave,
        })
      );
    } else {
      result = await dispatch(
        createRule({
          limits: limitsToSave,
          constraints: constraintsToSave,
        })
      );
    }
    if (result != null) {
      navigate('/privilege-card/admin/limits');
    }
  }, [
    constraints?.brand,
    constraints?.employeeTypes,
    constraints?.countries,
    constraints?.storeTypology,
    constraints?.productCategories,
    constraints?.skus,
    constraints?.nonEligibleConstraint,
    constraints?.validityStartDate,
    constraints?.validityEndDate,
    constraints?.categoryLabel,
    constraints?.categoryIcon,
    isValidLimit,
    maxAmount,
    maxQuantity,
    maxQuantityPerSku,
    ruleType,
    id,
    dispatch,
    navigate,
  ]);

  return (
    <div css={style.outerContainer}>
      <div css={style.ruleCardContainer}>
        <div css={style.sectionContainer}>
          <div css={style.sectionLabel}>{t('limitRuleset.ruleCard.limits')}</div>
          {ruleType === 'maxAmount' && (
            <AmountField
              label={t('limitRuleset.maxAmount')}
              showValidation={showValidation}
              defaultAmount={maxAmount}
              onChange={(value: MoneyAmount | null) => setMaxAmount(value)}
              validationMessage={t('limitRuleset.amountRequiredMessage')}
            />
          )}
          {ruleType === 'maxItems' && (
            <ItemsCountField
              label={t('limitRuleset.maxQuantity')}
              showValidation={showValidation}
              countValue={maxQuantity}
              onChange={value => setMaxQuantity(value)}
              validationMessage={t('limitRuleset.maxQuantityRequiredMessage')}
            />
          )}
          {ruleType === 'maxItemsBySku' && (
            <ItemsCountField
              label={t('limitRuleset.maxQuantityPerSKU')}
              showValidation={showValidation}
              countValue={maxQuantityPerSku}
              onChange={value => setMaxQuantityPerSku(value)}
              validationMessage={t('limitRuleset.maxQuantityPerSkuRequiredMessage')}
            />
          )}
          {ruleType === 'notEligible' && (
            <div css={style.notEligibleLabel}>{t('limitRuleset.notEligibleProducts')}</div>
          )}
        </div>
        <div css={style.sectionContainer}>
          <div css={style.sectionLabel}>{t('limitRuleset.ruleCard.constraints')}</div>
          <ConstraintsField
            showValidation={showValidation}
            defaultConstraints={limitRule?.constraints || null}
            constraints={constraints}
            ruleType={ruleType}
            onChange={(
              brand,
              employeeTypes,
              countries,
              storeTypology,
              productCategories,
              skus,
              nonEligibleConstraint,
              validityStartDate,
              validityEndDate,
              productCategoryLabel,
              productCategoryIcon
            ) =>
              setConstraints({
                ...(brand == null ? {} : { brand }),
                ...(employeeTypes == null ? {} : { employeeTypes }),
                countries,
                storeTypology,
                productCategories,
                skus,
                nonEligibleConstraint,
                validityStartDate,
                validityEndDate,
                categoryLabel: productCategoryLabel,
                categoryIcon: productCategoryIcon,
              })
            }
          />
        </div>
      </div>
      <div css={style.newRuleButtonContainer}>
        <div css={style.legendSection}>{t('mandatoryFields')}</div>
        <Button
          css={style.cancelRuleButton}
          icon='cancel'
          onClick={() => navigate('/privilege-card/admin/limits')}
          content={t('limitRuleset.cancel')}
          labelPosition='left'
        />
        <Button
          css={style.saveRuleButton}
          icon='save'
          onClick={saveLimitRule}
          content={t('limitRuleset.save')}
          labelPosition='left'
        />
      </div>
    </div>
  );
}

const ConstraintsField = ({
  defaultConstraints,
  constraints,
  showValidation,
  ruleType,
  onChange,
}: {
  defaultConstraints: Constraints | null;
  constraints: Partial<Constraints> | null;
  showValidation?: boolean;
  ruleType: LimitType;
  onChange: (
    brand: Brand | null,
    employeeType: string[] | null,
    country: Country[] | null,
    storeTypology: string | null,
    productCategory: string[] | null,
    skus: string[] | null,
    nonEligibleConstraint: NonEligibleConstraint | null,
    validityStartDate: Date | null,
    validityEndDate: Date | null,
    productCategoryLabel: string,
    productCategoryIcon: string
  ) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const principalBrands: Brand[] = useAppSelector(selectSortedPrincipalBrands);
  const employeeTypesOptions = useAppSelector(selectEmployeeTypes);
  const countriesOptions = useAppSelector(selectSortedCountries);
  const storeTypologiesOptions = storeTypologiesArray
  const productCategoriesOptions = useAppSelector(selectSortedProductCategories);
  const nonEligibleConstraintsOptions = useAppSelector(selectNonEligibleConstraints);
  const isFetchingNonEligibleConstraints = useAppSelector(selectIsFetchingNonEligibleConstraints);
  const skusOptions = useAppSelector(selectProductSkus);
  const styleLinesOptions = useAppSelector(selectSortedProductStyleLines);
  // const lskusOptions = useAppSelector(selectProductLskus)
  const isFetchingCountries = useAppSelector(selectIsFetchingCountries);
  const isFetchingProductCategories = useAppSelector(selectIsFetchingProductCategories);
  const isFetchingSkus = useAppSelector(selectIsFetchingSkus);
  const isFetchingStyleLines = useAppSelector(selectIsFetchingStyleLines);
  // const isFetchingLskus = useAppSelector(selectIsFetchingLskus);
  const [brand, setBrand] = useState<Brand | null>(defaultConstraints?.brand || null);
  const [employeeTypes, setEmployeeTypes] = useState<string[] | null>(defaultConstraints?.employeeTypes || null);
  const [storeTypology, setStoreTypology] = useState<string | null>(defaultConstraints?.storeTypology || null);
  const [countries, setCountries] = useState<Country[] | null>(defaultConstraints?.countries || null);
  const [productCategories, setProductCategories] = useState<string[] | null>(
    defaultConstraints?.productCategories || null
  );
  const [nonEligibleConstraint, setNonEligibleConstraint] = useState<NonEligibleConstraint | null>(
    defaultConstraints?.nonEligibleConstraint || null
  );
  const [skus, setSkus] = useState<string[] | null>(defaultConstraints?.skus || null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [validityStartDate, setValidityStartDate] = useState<Date | null>(
    defaultConstraints?.validityStartDate != null ? new Date(defaultConstraints?.validityStartDate) : new Date()
  );
  const [validityEndDate, setValidityEndDate] = useState<Date | null>(
    defaultConstraints?.validityEndDate != null ? new Date(defaultConstraints?.validityEndDate) : null
  );
  const [isBulkUploadModalOpen, setIsBulkUploadModalOpen] = useState(false);
  const [productCategoryLabel, setProductCategoryLabel] = useState(defaultConstraints?.categoryLabel || '');
  const [productCategoryIcon, setProductCategoryIcon] = useState(
    defaultConstraints?.categoryIcon != null && defaultConstraints?.categoryIcon !== ''
      ? defaultConstraints?.categoryIcon
      : 'shopping bag'
  );
  const productCategoryIcons: SemanticICONS[] = [
    'shopping bag',
    // 'briefcase',
    // 'suitcase',
    // 'male',
    // 'female',
    // 'snowflake',
    // 'sun',
    // 'gem',
    // 'umbrella',
  ];
  const generateProductCategoryIconOptions = (icon: SemanticICONS): DropdownItemProps => ({
    key: icon,
    value: icon,
    text: (
      <div>
        <Icon name={icon} css={style.selectedCategoryIcon} />
      </div>
    ),
    content: (
      <div>
        <Icon name={icon} css={style.categoryIcon} />
      </div>
    ),
  });
  const productCategoryIconOptions = productCategoryIcons.map(generateProductCategoryIconOptions);

  const brandIsValid = useMemo(() => constraints?.brand != null, [constraints?.brand]);
  const employeeTypesAreValid = useMemo(
    () => constraints?.employeeTypes != null && constraints.employeeTypes.length > 0,
    [constraints?.employeeTypes]
  );
  const nonEligibleConstraintIsValid = useMemo(
    () => constraints?.nonEligibleConstraint != null,
    [constraints?.nonEligibleConstraint]
  );

  const fetchDataByBrandCode = useCallback(
    (brandCode: string) => {
      dispatch(fetchCountries(brandCode));
      dispatch(fetchProductCategories(brandCode));
      dispatch(fetchNonEligibleConstraints(brandCode));
      dispatch(fetchProductSkus(brandCode));
      dispatch(fetchProductStyleLines(brandCode));
      // dispatch(fetchProductLskus(brandCode));
    },
    [dispatch]
  );

  const getDefaultConstraintByBrand = (brandCode: string): string => {
    const defaultConstraint = 'SKUs';
    const pomellatoConstraint = 'Style colors';
    switch (brandCode) {
      case 'POMELLATO':
        setNonEligibleConstraint(pomellatoConstraint);
        onChange(
          brand,
          employeeTypes,
          countries,
          storeTypology,
          productCategories,
          skus,
          pomellatoConstraint,
          validityStartDate,
          validityEndDate,
          productCategoryLabel,
          productCategoryIcon
        );
        return 'Style colors';
      case 'GUCCI':
      // case 'BOTTEGA_VENETA':
        return '';
      default:
        setNonEligibleConstraint('SKUs');
        onChange(
          brand,
          employeeTypes,
          countries,
          storeTypology,
          productCategories,
          skus,
          defaultConstraint,
          validityStartDate,
          validityEndDate,
          productCategoryLabel,
          productCategoryIcon
        );
        return 'SKUs';
    }
  };

  const getNonEligibleDropdownLabel = (constraint: string | null): string => {
    switch (constraint) {
      case 'SKUs':
        return t('limits.limitRules.skus');
      case 'Style colors':
        return t('limits.limitRules.styleColors');
      case 'Style lines':
        return t('limits.limitRules.styleLines');
      // case 'LSKUs':
      //   return t('limits.limitRules.lskus');
      default:
        return '';
    }
  };

  useEffect(() => {
    setBrand(defaultConstraints?.brand || null);
    setEmployeeTypes(defaultConstraints?.employeeTypes || null);
    setCountries(defaultConstraints?.countries || null);
    setStoreTypology(defaultConstraints?.storeTypology || null);
    setProductCategories(defaultConstraints?.productCategories || null);
    setSkus(defaultConstraints?.skus || null);
    setNonEligibleConstraint(defaultConstraints?.nonEligibleConstraint || null);
    setValidityStartDate(
      defaultConstraints?.validityStartDate != null ? new Date(defaultConstraints?.validityStartDate) : new Date()
    );
    setValidityEndDate(
      defaultConstraints?.validityEndDate != null ? new Date(defaultConstraints?.validityEndDate) : null
    );
    setProductCategoryLabel(defaultConstraints?.categoryLabel || '');
    setProductCategoryIcon(
      defaultConstraints?.categoryIcon != null && defaultConstraints?.categoryIcon !== ''
        ? defaultConstraints?.categoryIcon
        : 'shopping bag'
    );
    if (defaultConstraints?.brand != null) {
      fetchDataByBrandCode(defaultConstraints.brand.code);
    }
  }, [defaultConstraints, fetchDataByBrandCode]);
  

  return (
    <div css={style.constraintsSection}>
      <div css={style.groupedConstraints}>
        <FormField
          label={t('limitRuleset.brand')}
          editMode
          mandatory
          validationMessages={showValidation && !brandIsValid ? [t('limitRuleset.brandRequiredMessage')] : []}
          editComponent={
            <Dropdown
              css={style.brandField}
              placeholder={t('limitRuleset.brand')}
              value={brand?.code ?? ''}
              selection
              selectOnBlur={false}
              noResultsMessage={t('noResultsFound')}
              options={
                principalBrands.map(b => ({ 
                  key: b.description, 
                  text: b.description, 
                  value: b.code 
              }))}
              onChange={(event, data: DropdownProps) => {
                const brandCode = data.value as string;
                const selectedBrand = principalBrands.find(b => b.code === brandCode) as Brand;
                setCountries(null);
                setProductCategories(null);
                setSkus(null);
                setNonEligibleConstraint(null);
                setBrand(selectedBrand);
                if (selectedBrand != null) {
                  fetchDataByBrandCode(selectedBrand.code);
                }
                onChange(
                  selectedBrand,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  validityStartDate,
                  null,
                  productCategoryLabel,
                  productCategoryIcon
                );
              }}
            />
          }
        />
        <FormField
          label={t('limitRuleset.employeeType')}
          editMode
          mandatory
          validationMessages={
            showValidation && !employeeTypesAreValid ? [t('limitRuleset.employeeTypeRequiredMessage')] : []
          }
          editComponent={
            <Dropdown
              placeholder={t('limitRuleset.employeeType')}
              value={employeeTypes || []}
              selection
              clearable
              multiple
              options={
                employeeTypesOptions?.map(et => ({
                  key: et,
                  text: t(`limitRuleset.employeeTypes.${et}`),
                  value: et,
                })) || []
              }
              onChange={(event, data: DropdownProps) => {
                const selectedValue = data.value as string[];
                const selectedEmployeeTypes = selectedValue.length > 0 ? selectedValue : null;
                selectedEmployeeTypes?.sort();
                setEmployeeTypes(selectedEmployeeTypes);
                onChange(
                  brand,
                  selectedEmployeeTypes,
                  countries,
                  storeTypology,
                  productCategories,
                  skus,
                  nonEligibleConstraint,
                  validityStartDate,
                  validityEndDate,
                  productCategoryLabel,
                  productCategoryIcon
                );
              }}
            />
          }
        />
        <FormField
          label={t('limitRuleset.countries')}
          editMode
          editComponent={
            <Dropdown
              placeholder={t('limitRuleset.allCountries')}
              value={countries == null ? [] : countries.map(c => c.code)}
              selection
              multiple
              noResultsMessage={t('noResultsFound')}
              clearable
              search
              loading={isFetchingCountries}
              options={countriesOptions.map(c => ({
                key: c.id,
                text: c.description,
                value: c.code,
              }))}
              onChange={(event, data: DropdownProps) => {
                const selectedValues = data.value as string[];
                const selectedCountries =
                  selectedValues.length > 0 ? countriesOptions.filter(c => selectedValues.includes(c.code)) : null;
                setCountries(selectedCountries);
                onChange(
                  brand,
                  employeeTypes,
                  selectedCountries,
                  storeTypology,
                  productCategories,
                  skus,
                  nonEligibleConstraint,
                  validityStartDate,
                  validityEndDate,
                  productCategoryLabel,
                  productCategoryIcon
                );
              }}
            />
          }
        />
        {brand != null && brand.code === 'GUCCI' && (
          <FormField
                label={t('limitRuleset.storeTypology')}
                editMode
                editComponent={
                  <Dropdown
                    placeholder={t('limitRuleset.allTypologies')}
                    value={storeTypology ?? ''}
                    clearable
                    selection
                    options={
                      storeTypologiesOptions?.map(s => ({
                        key: s,
                        text: s,
                        value: s,
                      })) || []
                    }
                    onChange={(event, data: DropdownProps) => {
                      const selectedTypology = data.value as string;
                      const selectedStoreTypology = selectedTypology === '' ? null : selectedTypology
                      setStoreTypology(selectedStoreTypology)
                      onChange(
                        brand,
                        employeeTypes,
                        countries,
                        selectedStoreTypology,
                        productCategories,
                        skus,
                        nonEligibleConstraint,
                        validityStartDate,
                        validityEndDate,
                        productCategoryLabel,
                        productCategoryIcon
                      );
                    }}
                  />
                }
              />
        )}
      </div>
      <div css={style.groupedConstraints}>
        <FormField
          label={t('limitRuleset.productCategoryLabel')}
          editMode
          editComponent={
            <Input
              value={productCategoryLabel}
              placeholder={t('limitRuleset.productCategoryLabel')}
              onChange={element => {
                setProductCategoryLabel(element.target.value);
                onChange(
                  brand,
                  employeeTypes,
                  countries,
                  storeTypology,
                  productCategories,
                  skus,
                  nonEligibleConstraint,
                  validityStartDate,
                  validityEndDate,
                  element.target.value,
                  productCategoryIcon
                );
              }}
              css={style.productCategoryLabel}
            />
          }
        />
        <FormField
          label={t('limitRuleset.productCategoryIcon')}
          editMode
          css={style.iconDropdownField}
          editComponent={
            <Dropdown
              css={style.iconDropdown}
              value={productCategoryIcon}
              selection
              options={productCategoryIconOptions}
              onChange={(event, data: DropdownProps) => {
                const selectedIcon = data.value as string;
                setProductCategoryIcon(selectedIcon);
                onChange(
                  brand,
                  employeeTypes,
                  countries,
                  storeTypology,
                  productCategories,
                  skus,
                  nonEligibleConstraint,
                  validityStartDate,
                  validityEndDate,
                  productCategoryLabel,
                  selectedIcon
                );
              }}
            />
          }
        />
        <FormField
          label={t('limitRuleset.productCategories')}
          editMode
          editComponent={
            <Dropdown
              placeholder={t('limitRuleset.allProductCategories')}
              value={productCategories ?? []}
              selection
              multiple
              noResultsMessage={t('noResultsFound')}
              search
              clearable
              loading={isFetchingProductCategories}
              options={
                productCategoriesOptions?.map(pc => ({
                  key: pc,
                  text: pc,
                  value: pc,
                })) || []
              }
              onChange={(event, data: DropdownProps) => {
                const selectedValues = data.value as string[];
                const selectedProductCategories = selectedValues.length > 0 ? selectedValues : null;
                selectedProductCategories?.sort();
                setProductCategories(selectedProductCategories);
                onChange(
                  brand,
                  employeeTypes,
                  countries,
                  storeTypology,
                  selectedProductCategories,
                  skus,
                  nonEligibleConstraint,
                  validityStartDate,
                  validityEndDate,
                  productCategoryLabel,
                  productCategoryIcon
                );
              }}
            />
          }
        />
      </div>
      {ruleType === 'notEligible' && (
        <div css={style.groupedConstraints}>
          <FormField
            label={t('limitRuleset.validityStartDate')}
            editMode
            editComponent={
              <DatePicker
                css={style.datePicker}
                dateFormat='yyyy-MM-dd'
                placeholderText={t('limitRuleset.selectValidityStartDate')}
                minDate={new Date()}
                selected={validityStartDate}
                onChange={(date: Date | null) => {
                  const selectedValidityStartDate = date != null ? (date as Date) : null;
                  setValidityStartDate(date);
                  onChange(
                    brand,
                    employeeTypes,
                    countries,
                    storeTypology,
                    productCategories,
                    skus,
                    nonEligibleConstraint,
                    selectedValidityStartDate,
                    validityEndDate,
                    productCategoryLabel,
                    productCategoryIcon
                  );
                }}
              />
            }
          />
          <FormField
            label={t('limitRuleset.validityEndDate')}
            editMode
            editComponent={
              <DatePicker
                css={style.datePicker}
                placeholderText={t('limitRuleset.selectValidityEndDate')}
                minDate={validityStartDate}
                maxDate={addYears(new Date(), 5)}
                dateFormat='yyyy-MM-dd'
                selected={validityEndDate}
                onChange={(date: Date | null) => {
                  const selectedValidityEndDate = date != null ? (date as Date) : null;
                  setValidityEndDate(selectedValidityEndDate);
                  onChange(
                    brand,
                    employeeTypes,
                    countries,
                    storeTypology,
                    productCategories,
                    skus,
                    nonEligibleConstraint,
                    validityStartDate,
                    selectedValidityEndDate,
                    productCategoryLabel,
                    productCategoryIcon
                  );
                }}
              />
            }
          />
        </div>
      )}
      {brand != null && ruleType === 'notEligible' && (
        <div>
          <div css={style.groupedConstraints}>
            {selectedFileName !== '' && (
              <div css={style.filepathLabel}>
                <Icon
                  css={style.resetFilePathButton}
                  name='times'
                  color='black'
                  onClick={() => {
                    setSelectedFileName('');
                  }}
                />
                {t('limitRuleset.nonEligibleBulkUpload.fileSelected')}: <i>{selectedFileName}</i>
              </div>
            )}
            {selectedFileName === '' && (
              <FormField
                label={t('limitRuleset.nonEligibleConstraint')}
                editMode
                mandatory
                css={style.nonEligibleConstraintDropdown}
                validationMessages={
                  showValidation && !nonEligibleConstraintIsValid
                    ? [t('limitRuleset.nonEligibleConstraintIsRequired')]
                    : []
                }
                editComponent={
                  <Dropdown
                    placeholder={t('limitRuleset.selectConstraint')}
                    value={nonEligibleConstraint ?? getDefaultConstraintByBrand(brand?.code)}
                    selection
                    upward
                    loading={isFetchingNonEligibleConstraints}
                    disabled={selectedFileName !== ''}
                    noResultsMessage={t('noResultsFound')}
                    options={
                      nonEligibleConstraintsOptions?.map(c => ({
                        key: c,
                        text: c,
                        value: c,
                      })) || []
                    }
                    onChange={(event, data: DropdownProps) => {
                      const selectedConstraint = data.value as NonEligibleConstraint;
                      setNonEligibleConstraint(selectedConstraint);
                      onChange(
                        brand,
                        employeeTypes,
                        countries,
                        storeTypology,
                        productCategories,
                        skus,
                        selectedConstraint,
                        validityStartDate,
                        validityEndDate,
                        productCategoryLabel,
                        productCategoryIcon
                      );
                    }}
                  />
                }
              />
            )}
            {selectedFileName === '' && (
              <FormField
                label={getNonEligibleDropdownLabel(nonEligibleConstraint)}
                editMode
                editComponent={
                  <NonEligibleConstraintsDropdown
                    isFetchingSkus={isFetchingSkus}
                    skusOptions={skusOptions}
                    setSkus={setSkus}
                    isFetchingStyleLines={isFetchingStyleLines}
                    styleLinesOptions={styleLinesOptions}
                    // isFetchingLskus={isFetchingLskus}
                    // lskusOptions={lskusOptions}
                    onChange={onChange}
                    brand={brand}
                    employeeTypes={employeeTypes}
                    countries={countries}
                    storeTypology={storeTypology}
                    productCategories={productCategories}
                    skus={skus}
                    nonEligibleConstraint={nonEligibleConstraint}
                    validityStartDate={validityStartDate}
                    validityEndDate={validityEndDate}
                    productCategoryLabel={productCategoryLabel}
                    productCategoryIcon={productCategoryIcon}
                    fileName={selectedFileName}
                  />
                }
              />
            )}
            {nonEligibleConstraint != null && (
              <div css={style.bulkActionsBtnContainer(selectedFileName)}>
                <Button
                  content={t('limitRuleset.nonEligibleBulkUpload.bulkUpload')}
                  css={style.bulkActionsBtn}
                  icon='cloud upload'
                  labelPosition='left'
                  onClick={() => {
                    setIsBulkUploadModalOpen(true);
                  }}
                />
              </div>
            )}
          </div>
          {isBulkUploadModalOpen && (
            <NonEligibleBulkUploadModal
              open={isBulkUploadModalOpen}
              onClose={() => {
                setIsBulkUploadModalOpen(false);
                setSelectedFileName('');
              }}
              nonEligibleConstraint={nonEligibleConstraint}
              onConfirm={(skusFromFile: string[]) => {
                setIsBulkUploadModalOpen(false);
                setSkus(skusFromFile);
                onChange(
                  brand,
                  employeeTypes,
                  countries,
                  storeTypology,
                  productCategories,
                  skus == null ? skusFromFile : skus.concat(skusFromFile),
                  nonEligibleConstraint,
                  validityStartDate,
                  validityEndDate,
                  productCategoryLabel,
                  productCategoryIcon
                );
              }}
              fileName={selectedFileName}
              setFileName={setSelectedFileName}
            />
          )}
        </div>
      )}
    </div>
  );
};

const NonEligibleConstraintsDropdown = ({
  isFetchingSkus,
  skusOptions,
  setSkus,
  isFetchingStyleLines,
  styleLinesOptions,
  // isFetchingLskus,
  // lskusOptions,
  brand,
  employeeTypes,
  countries,
  storeTypology,
  productCategories,
  skus,
  nonEligibleConstraint,
  validityStartDate,
  validityEndDate,
  productCategoryLabel,
  productCategoryIcon,
  onChange,
  fileName,
}: {
  isFetchingSkus: boolean;
  skusOptions: ProductSku[] | null;
  setSkus: React.Dispatch<React.SetStateAction<string[] | null>>;
  isFetchingStyleLines: boolean;
  styleLinesOptions: string[] | null;
  // isFetchingLskus: boolean;
  // lskusOptions: string[] | null;
  brand: Brand | null;
  employeeTypes: string[] | null;
  countries: Country[] | null;
  storeTypology: string | null;
  productCategories: string[] | null;
  skus: string[] | null;
  nonEligibleConstraint: NonEligibleConstraint | null;
  validityStartDate: Date | null;
  validityEndDate: Date | null;
  productCategoryLabel: string;
  productCategoryIcon: string;
  onChange: (
    brand: Brand | null,
    employeeType: string[] | null,
    country: Country[] | null,
    storeTypology: string | null,
    productCategory: string[] | null,
    skus: string[] | null,
    nonEligibleConstraint: NonEligibleConstraint | null,
    validityStartDate: Date | null,
    validityEndDate: Date | null,
    productCategoryLabel: string,
    productCategoryIcon: string
  ) => void;
  fileName: string;
}): JSX.Element => {
  const { t } = useTranslation();

  switch (nonEligibleConstraint) {
    case 'SKUs':
    case 'Style colors':
      return (
        <Dropdown
          placeholder={nonEligibleConstraint === 'SKUs' ? t('limitRuleset.skus') : t('limitRuleset.styleColors')}
          value={skus ?? []}
          search
          selection
          multiple
          disabled={fileName !== ''}
          loading={isFetchingSkus}
          noResultsMessage={t('noResultsFound')}
          clearable
          options={skusOptions?.map(s => ({ key: s.sku, text: s.sku, value: s.sku })) || []}
          onChange={(event, data: DropdownProps) => {
            const selectedValues = data.value as string[];
            const selectedSkus = selectedValues.length > 0 ? selectedValues : null;
            setSkus(selectedSkus);
            onChange(
              brand,
              employeeTypes,
              countries,
              storeTypology,
              productCategories,
              selectedSkus,
              nonEligibleConstraint,
              validityStartDate,
              validityEndDate,
              productCategoryLabel,
              productCategoryIcon
            );
          }}
        />
      );
    case 'Style lines':
      return (
        <Dropdown
          placeholder={t('limitRuleset.allStyleLines')}
          value={skus ?? []}
          search
          selection
          multiple
          disabled={fileName !== ''}
          loading={isFetchingStyleLines}
          noResultsMessage={t('noResultsFound')}
          clearable
          options={styleLinesOptions?.map(s => ({ key: s, text: s, value: s })) || []}
          onChange={(event, data: DropdownProps) => {
            const selectedValues = data.value as string[];
            const selectedStyleLines = selectedValues.length > 0 ? selectedValues : null;
            selectedStyleLines?.sort();
            setSkus(selectedStyleLines);
            onChange(
              brand,
              employeeTypes,
              countries,
              storeTypology,
              productCategories,
              selectedStyleLines,
              nonEligibleConstraint,
              validityStartDate,
              validityEndDate,
              productCategoryLabel,
              productCategoryIcon
            );
          }}
        />
      );
    // case 'LSKUs':
    //   return (
    //     <Dropdown
    //       placeholder={t('limitRuleset.allLskus')}
    //       value={skus ?? []}
    //       search
    //       selection
    //       multiple
    //       disabled={fileName !== ''}
    //       loading={isFetchingLskus}
    //       noResultsMessage={t('noResultsFound')}
    //       clearable
    //       options={lskusOptions?.map(s => ({ key: s, text: s, value: s })) || []}
    //       onChange={(event, data: DropdownProps) => {
    //         const selectedValues = data.value as string[];
    //         const selectedLskus = selectedValues.length > 0 ? selectedValues : null;
    //         setSkus(selectedLskus);
    //         onChange(
    //           brand,
    //           employeeTypes,
    //           countries,
    //           storeTypology,
    //           productCategories,
    //           selectedLskus,
    //           nonEligibleConstraint,
    //           validityStartDate,
    //           validityEndDate,
    //           productCategoryLabel,
    //           productCategoryIcon
    //         );
    //       }}
    //     />
    //   );
    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
  }
};
