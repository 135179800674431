/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef } from 'react';
import { useZxing } from 'react-zxing';
import { useTranslation } from 'react-i18next';
import style from './barcodeScanner.style';

export const BarcodeScanner = ({
  onBarcodeDetection,
  storeBrandCode,
}: {
  onBarcodeDetection: (barcode: string) => void;
  storeBrandCode: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const isGucciStore = storeBrandCode === 'GUCCI';
  const [isScanning, setIsScanning] = useState(false);
  const [lastBarcode, setLastBarcode] = useState<string | null>(null); 

  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoRef.current) {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: 'environment',  
            width: 640,                 
            height: 480,                
          },
        })
        .then((stream) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
          }
        })
        .catch((err) => {
          console.error("Error accessing camera:", err);
        });
    }
  }, []); 

  const { ref } = useZxing({
    onResult(r) {
      const barcode = r.getText();
      const isValidBarcodeCode = /^\d+$/.test(barcode);
      const resizedBarcode =
        isGucciStore && barcode.length === 10 ? barcode.slice(0, -1) : barcode;

      if (isValidBarcodeCode && barcode !== lastBarcode && !isScanning) {

        setIsScanning(true);
        setLastBarcode(barcode); 
        onBarcodeDetection(resizedBarcode);

        setTimeout(() => {
          setIsScanning(false);
        }, 300); 
      }
    },
    timeBetweenDecodingAttempts: 200, 
  });

  return (
    <div css={style.cameraScan}>
      <div css={style.cameraContainer}>
        <div css={style.targetSquareMessage}>{t('codeScanner.scanBarcode')}</div>
        <video css={style.video} ref={ref} muted />
        <BarcodeSquare />
      </div>
    </div>
  );
};

const BarcodeSquare = (): JSX.Element => {
  return (
    <div css={style.barcodeSquareContainer}>
      <div css={style.targetSquare}>
        <div css={style.corners} />
      </div>
    </div>
  );
};

