import { Action, AnyAction, combineReducers, configureStore, createAction, ThunkAction } from '@reduxjs/toolkit';
import { deleteJwtToken } from './services/webStorageService';
import principalReducer from '../features/auth/store/principalSlice';
import brandsReducer from '../shared/store/brandsSlice';
import principalEmployeeCardReducer from '../features/employee/discountCard/store/principalEmployeeCardSlice';
import principalBusinessCardReducer from '../features/employee/businessCard/store/businessCardSlice';
import employeeTypesReducer from '../shared/store/employeeTypesSlice';
import countriesReducer from '../features/admin/limits/stores/countriesSlice';
import productCategoriesReducer from '../features/admin/limits/stores/productCategoriesSlice';
import productSkusReducer from '../features/admin/limits/stores/productSkusSlice';
import productStyleLinesReducer from '../features/admin/limits/stores/productStyleLinesSlice';
import productLskusReducer from '../features/admin/limits/stores/productLskusSlice';
import nonEligibleConstraintsReducer from '../features/admin/limits/stores/nonEligibleConstraintsSlice';
import purchaseHistoryReducer from '../features/employee/purchaseHistory/store/purchaseHistorySlice';
import purchaseHistoryFiltersReducer from '../features/employee/purchaseHistory/store/purchaseHistoryFiltersSlice';
import usersManagementReducer from '../features/admin/usersManagement/stores/usersManagementSlice';
import limitRulesReducer from '../features/admin/limits/stores/limitRulesSlice';
import limitRulesFiltersReducer from '../features/admin/limits/stores/limitRulesFiltersSlice';
import limitRuleDetailReducer from '../features/admin/limits/stores/limitRuleDetailSlice';
import employeeDiscountCardReducer from '../features/salesAssistant/cardSearch/store/employeeDiscountCardSlice';
import productSearchReducer from '../features/salesAssistant/products/store/productSearchSlice';
import employeePurchaseLimitReducer from '../features/employee/purchaseLimit/store/employeePurchaseLimitSlice';
import employeePurchaseLimitFiltersReducer from '../features/employee/purchaseLimit/store/employeePurchaseLimitFiltersSlice';
import trafficLightsReducer from '../features/salesAssistant/trafficLights/store/trafficLightsSlice';
import trafficLightsFilterReducer from '../features/salesAssistant/trafficLights/store/trafficLightsFilterSlice';
import salesAssistantPurchaseLimitFilterReducer from '../features/salesAssistant/limit/store/salesAssistantPurchaseLimitFilterSlice';
import salesAssistantPurchaseLimitReducer from '../features/salesAssistant/limit/store/salesAssistantPurchaseLimitSlice';
import salesAssistantReducer from '../features/salesAssistant/cardSearch/store/salesAssistantSlice';
import discountRuleSetsReducer from '../features/admin/discounts/store/discountRuleSetsSlice';
import discountRuleSetsFiltersReducer from '../features/admin/discounts/store/discountRuleSetsFiltersSlice';
import discountRuleSetDetailReducer from '../features/admin/discounts/store/discountRuleSetDetailSlice';
import discountCountriesReducer from '../features/admin/discounts/store/discountCountriesSlice';
import discountProductCategoriesReducer from '../features/admin/discounts/store/discountProductCategoriesSlice';
import blockedEmployeesReducer from '../features/admin/blockedEmployees/stores/blockedEmployeesSlice';
import blockedEmployeeDetailReducer from '../features/admin/blockedEmployees/stores/blockedEmployeeDetailSlice';
import specialCasesReducer from '../features/admin/discounts/store/specialCasesSlice';
import storesReducer from '../features/salesAssistant/storeSelection/stores/storesSlice';
import basketReducer from '../features/salesAssistant/products/store/basketSlice';
import productImageReducer from '../features/salesAssistant/products/store/productImageSlice';
import userActivitiesReducer from '../features/admin/userActivities/stores/userActivitiesSlice';
import userActivitiesFiltersReducer from '../features/admin/userActivities/stores/userActivitiesFiltersSlice';

export const logoutAction = createAction<void>('LOGOUT');

const combinedReducer = combineReducers({
  principal: principalReducer,
  principalEmployeeCard: principalEmployeeCardReducer,
  principalBusinessCard: principalBusinessCardReducer,
  purchaseHistory: purchaseHistoryReducer,
  purchaseHistoryFilters: purchaseHistoryFiltersReducer,
  usersManagement: usersManagementReducer,
  brands: brandsReducer,
  employeeTypes: employeeTypesReducer,
  productCategories: productCategoriesReducer,
  countries: countriesReducer,
  productSkus: productSkusReducer,
  productStyleLines: productStyleLinesReducer,
  productLskus: productLskusReducer,
  nonEligibleConstraints: nonEligibleConstraintsReducer,
  limitRules: limitRulesReducer,
  limitRulesFilters: limitRulesFiltersReducer,
  limitRuleDetail: limitRuleDetailReducer,
  employeeDiscountCard: employeeDiscountCardReducer,
  productSearch: productSearchReducer,
  employeePurchaseLimit: employeePurchaseLimitReducer,
  employeePurchaseLimitFilters: employeePurchaseLimitFiltersReducer,
  trafficLights: trafficLightsReducer,
  trafficLightsFilter: trafficLightsFilterReducer,
  salesAssistantPurchaseLimitFilter: salesAssistantPurchaseLimitFilterReducer,
  salesAssistantPurchaseLimit: salesAssistantPurchaseLimitReducer,
  salesAssistant: salesAssistantReducer,
  discountRuleSets: discountRuleSetsReducer,
  discountRuleSetsFilters: discountRuleSetsFiltersReducer,
  discountRuleSetDetail: discountRuleSetDetailReducer,
  discountProductCategories: discountProductCategoriesReducer,
  discountCountries: discountCountriesReducer,
  blockedEmployees: blockedEmployeesReducer,
  blockedEmployeeDetail: blockedEmployeeDetailReducer,
  discountSpecialCases: specialCasesReducer,
  stores: storesReducer,
  basket: basketReducer,
  productImage: productImageReducer,
  userActivities: userActivitiesReducer,
  userActivitiesFilters: userActivitiesFiltersReducer,
});

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === 'LOGOUT') {
    deleteJwtToken();
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV === 'development',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof combinedReducer>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>;
