import _ from 'lodash';

interface Currency {
  code: string;
  description: string;
}

const currencies: Currency[] =  [
  { code: 'AED', description: 'United Arab Emirates Dirham' },
  { code: 'AFN', description: 'Afghan Afghani' },
  { code: 'ALL', description: 'Albanian Lek' },
  { code: 'AMD', description: 'Armenian Dram' },
  { code: 'ANG', description: 'Netherlands Antillean Guilder' },
  { code: 'AOA', description: 'Angolan Kwanza' },
  { code: 'ARS', description: 'Argentine Peso' },
  { code: 'AUD', description: 'Australian Dollar' },
  { code: 'AWG', description: 'Aruban Florin' },
  { code: 'AZN', description: 'Azerbaijani Manat' },
  { code: 'BAM', description: 'Bosnia-Herzegovina Convertible Mark' },
  { code: 'BBD', description: 'Barbadian Dollar' },
  { code: 'BDT', description: 'Bangladeshi Taka' },
  { code: 'BGN', description: 'Bulgarian Lev' },
  { code: 'BHD', description: 'Bahraini Dinar' },
  { code: 'BIF', description: 'Burundian Franc' },
  { code: 'BMD', description: 'Bermudian Dollar' },
  { code: 'BND', description: 'Brunei Dollar' },
  { code: 'BOB', description: 'Bolivian Boliviano' },
  { code: 'BRL', description: 'Brazilian Real' },
  { code: 'BSD', description: 'Bahamian Dollar' },
  { code: 'BTN', description: 'Bhutanese Ngultrum' },
  { code: 'BWP', description: 'Botswana Pula' },
  { code: 'BYN', description: 'Belarusian Ruble' },
  { code: 'BZD', description: 'Belize Dollar' },
  { code: 'CAD', description: 'Canadian Dollar' },
  { code: 'CDF', description: 'Congolese Franc' },
  { code: 'CHF', description: 'Swiss Franc' },
  { code: 'CLP', description: 'Chilean Peso' },
  { code: 'CNY', description: 'Chinese Yuan Renminbi' },
  { code: 'COP', description: 'Colombian Peso' },
  { code: 'CRC', description: 'Costa Rican Colón' },
  { code: 'CUP', description: 'Cuban Peso' },
  { code: 'CVE', description: 'Cape Verdean Escudo' },
  { code: 'CZK', description: 'Czech Koruna' },
  { code: 'DJF', description: 'Djiboutian Franc' },
  { code: 'DKK', description: 'Danish Krone' },
  { code: 'DOP', description: 'Dominican Peso' },
  { code: 'DZD', description: 'Algerian Dinar' },
  { code: 'EGP', description: 'Egyptian Pound' },
  { code: 'ERN', description: 'Eritrean Nakfa' },
  { code: 'ETB', description: 'Ethiopian Birr' },
  { code: 'EUR', description: 'Euro' },
  { code: 'FJD', description: 'Fijian Dollar' },
  { code: 'FKP', description: 'Falkland Islands Pound' },
  { code: 'GBP', description: 'British Pound Sterling' },
  { code: 'GEL', description: 'Georgian Lari' },
  { code: 'GHS', description: 'Ghanaian Cedi' },
  { code: 'GIP', description: 'Gibraltar Pound' },
  { code: 'GMD', description: 'Gambian Dalasi' },
  { code: 'GNF', description: 'Guinean Franc' },
  { code: 'GTQ', description: 'Guatemalan Quetzal' },
  { code: 'GYD', description: 'Guyanese Dollar' },
  { code: 'HKD', description: 'Hong Kong Dollar' },
  { code: 'HNL', description: 'Honduran Lempira' },
  { code: 'HRK', description: 'Croatian Kuna' },
  { code: 'HTG', description: 'Haitian Gourde' },
  { code: 'HUF', description: 'Hungarian Forint' },
  { code: 'IDR', description: 'Indonesian Rupiah' },
  { code: 'ILS', description: 'Israeli New Shekel' },
  { code: 'INR', description: 'Indian Rupee' },
  { code: 'IQD', description: 'Iraqi Dinar' },
  { code: 'IRR', description: 'Iranian Rial' },
  { code: 'ISK', description: 'Icelandic Króna' },
  { code: 'JMD', description: 'Jamaican Dollar' },
  { code: 'JOD', description: 'Jordanian Dinar' },
  { code: 'JPY', description: 'Japanese Yen' },
  { code: 'KES', description: 'Kenyan Shilling' },
  { code: 'KGS', description: 'Kyrgyzstani Som' },
  { code: 'KHR', description: 'Cambodian Riel' },
  { code: 'KMF', description: 'Comorian Franc' },
  { code: 'KRW', description: 'South Korean Won' },
  { code: 'KWD', description: 'Kuwaiti Dinar' },
  { code: 'KYD', description: 'Cayman Islands Dollar' },
  { code: 'KZT', description: 'Kazakhstani Tenge' },
  { code: 'LAK', description: 'Lao Kip' },
  { code: 'LBP', description: 'Lebanese Pound' },
  { code: 'LKR', description: 'Sri Lankan Rupee' },
  { code: 'LRD', description: 'Liberian Dollar' },
  { code: 'LSL', description: 'Lesotho Loti' },
  { code: 'LYD', description: 'Libyan Dinar' },
  { code: 'MAD', description: 'Moroccan Dirham' },
  { code: 'MDL', description: 'Moldovan Leu' },
  { code: 'MGA', description: 'Malagasy Ariary' },
  { code: 'MKD', description: 'Macedonian Denar' },
  { code: 'MMK', description: 'Myanmar Kyat' },
  { code: 'MNT', description: 'Mongolian Tögrög' },
  { code: 'MOP', description: 'Macanese Pataca' },
  { code: 'MUR', description: 'Mauritian Rupee' },
  { code: 'MVR', description: 'Maldivian Rufiyaa' },
  { code: 'MWK', description: 'Malawian Kwacha' },
  { code: 'MXN', description: 'Mexican Peso' },
  { code: 'MYR', description: 'Malaysian Ringgit' },
  { code: 'NAD', description: 'Namibian Dollar' },
  { code: 'NGN', description: 'Nigerian Naira' },
  { code: 'NOK', description: 'Norwegian Krone' },
  { code: 'NPR', description: 'Nepalese Rupee' },
  { code: 'NZD', description: 'New Zealand Dollar' },
  { code: 'OMR', description: 'Omani Rial' },
  { code: 'PAB', description: 'Panamanian Balboa' },
  { code: 'PEN', description: 'Peruvian Sol' },
  { code: 'PHP', description: 'Philippine Peso' },
  { code: 'PKR', description: 'Pakistani Rupee' },
  { code: 'PLN', description: 'Polish Złoty' },
  { code: 'PYG', description: 'Paraguayan Guaraní' },
  { code: 'QAR', description: 'Qatari Riyal' },
  { code: 'RON', description: 'Romanian Leu' },
  { code: 'RSD', description: 'Serbian Dinar' },
  { code: 'RUB', description: 'Russian Ruble' },
  { code: 'SAR', description: 'Saudi Riyal' },
  { code: 'SEK', description: 'Swedish Krona' },
  { code: 'SGD', description: 'Singapore Dollar' },
  { code: 'THB', description: 'Thai Baht' },
  { code: 'TRY', description: 'Turkish Lira' },
  { code: 'TWD', description: 'New Taiwan Dollar' },
  { code: 'USD', description: 'United States Dollar' },
  { code: 'VND', description: 'Vietnamese Dong' },
  { code: 'ZAR', description: 'South African Rand' }
]


export const orderedCurrencies: Currency[] = _.sortBy(currencies, 'code');
