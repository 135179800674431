import { createSlice, Draft, PayloadAction, createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import { Country } from '../../../../shared/models/country';
import type { AppThunk, RootState } from '../../../../core/store';
import { selectSelectedStore } from '../../cardSearch/store/salesAssistantSlice';
import { selectEligibleLimitRules } from '../../../admin/limits/stores/limitRulesSlice';

interface FilterData {
  countries: Country[];
  employeeTypes: string[];
}
export interface Filters {
  country: Country;
  employeeType: string;
  brand: string;
}
export enum WIZARD_STEP {
  'SELECT_DC_EMPLOYEE_CATEGORY',
  'SEARCH_LIMITS',
}
interface SalesAssistantPurchaseLimitFilterSliceState {
  data: FilterData;
  isFetching: boolean;
  error: string;
  searchBoxFilter: string;
  filters: Filters;
}

const initialState: SalesAssistantPurchaseLimitFilterSliceState = {
  data: {
    countries: [],
    employeeTypes: [],
  },
  isFetching: false,
  error: '',
  searchBoxFilter: '',
  filters: {
    country: { id: '', code: '', description: '' },
    employeeType: '',
    brand: '',
  },
};


export const salesAssistantPurchaseLimitFilterSlice = createSlice({
  name: 'salesAssistantPurchaseLimitFilter',
  initialState,
  reducers: {
    startFetch: (
      state: Draft<SalesAssistantPurchaseLimitFilterSliceState>
    ): SalesAssistantPurchaseLimitFilterSliceState => {
      return {
        ...state,
        isFetching: true,
      };
    },
    finishFetchFilters: (
      state: Draft<SalesAssistantPurchaseLimitFilterSliceState>,
      payloadAction: PayloadAction<FilterData>
    ): SalesAssistantPurchaseLimitFilterSliceState => {
      return {
        ...state,
        data: payloadAction.payload,
      };
    },
    httpError: (
      state: Draft<SalesAssistantPurchaseLimitFilterSliceState>,
      payloadAction: PayloadAction<string>
    ): SalesAssistantPurchaseLimitFilterSliceState => {
      return {
        ...state,
        isFetching: false,
        error: payloadAction.payload,
      };
    },
    setSearchBoxFilter: (
      state: Draft<SalesAssistantPurchaseLimitFilterSliceState>,
      action: PayloadAction<string>
    ): SalesAssistantPurchaseLimitFilterSliceState => ({
      ...state,
      searchBoxFilter: action.payload,
    }),
    setFilters: (
      state: Draft<SalesAssistantPurchaseLimitFilterSliceState>,
      action: PayloadAction<Filters>
    ): SalesAssistantPurchaseLimitFilterSliceState => ({
      ...state,
      filters: {
        ...state.filters,
        ...action.payload,
      },
    }),

    resetFilters: (
      state: Draft<SalesAssistantPurchaseLimitFilterSliceState>
    ): SalesAssistantPurchaseLimitFilterSliceState => {
      return { ...state, filters: { ...initialState.filters } };
    },
  },
});

export const { startFetch, httpError, setSearchBoxFilter, setFilters, finishFetchFilters, resetFilters } =
  salesAssistantPurchaseLimitFilterSlice.actions;

export const changeSearchBoxFilter =
  (value: string): AppThunk =>
  async dispatch => {
    dispatch(setSearchBoxFilter(value));
  };
export const changeFilters =
  (filters: Filters): AppThunk =>
  async dispatch => {
    dispatch(setFilters(filters));
  };

export const clearFilters = (): AppThunk => async dispatch => {
  dispatch(resetFilters());
};

export const fetchFilters = (): AppThunk => async (dispatch, state) => {
  dispatch(startFetch());
  try {
    const countriesArray: Country[] = [];
    state().limitRules.limitRules.forEach(l => {
      if (l.constraints.countries != null) {
        countriesArray.push(...l.constraints.countries);
      }
    });

    const countries: Country[] = _.uniqBy(countriesArray, c => c.code);
    const employeeTypes: string[] = state().employeeTypes.data as string[];
    const filters: FilterData = {
      countries,
      employeeTypes,
    };
    dispatch(finishFetchFilters(filters));
  } catch (e) {
    dispatch(httpError(JSON.stringify(e)));
  }
};

export const selectCountries = (state: RootState): Country[] => state.limitRulesFilters.data.countries;

export const selectSortedCountriesFilteredByStoreBrand = createSelector(
  selectSelectedStore,
  selectEligibleLimitRules,
  (store, limitRules): Country[] => {
    if (store == null) {
      return [];
    }
    const storeCountry: Country = {
      id: store.countryCode,
      code: store.countryCode,
      description: store.countryDescription,
    };
    const countries: Country[] = [];
    const storeRules = limitRules.filter(l => l.constraints.brand.code === store.brand.code);

    storeRules.forEach(rule => {
      if (!_.isNull(rule.constraints.countries)) {
        countries.push(...rule.constraints.countries);
      }
    });
    if (countries.find(country => country.code === store.countryCode) === undefined) {
      countries.push(storeCountry);
    }
    return _.sortBy(
      _.uniqBy(countries, c => c.code),
      ['description']
    );
  }
);

export const selectEmployeeTypes = (state: RootState): string[] => state.limitRulesFilters.data.employeeTypes;
export const selectSearchBoxFilter = (state: RootState): string =>
  state.salesAssistantPurchaseLimitFilter.searchBoxFilter;
export const selectSAPurchaseLimitFilters = (state: RootState): Filters =>
  state.salesAssistantPurchaseLimitFilter.filters;

export default salesAssistantPurchaseLimitFilterSlice.reducer;
