import { APP_ENV } from '../../constants';

export const checkValueInclude = (value: string, filter: string): boolean => {
  return filter === '' || value.toLowerCase().includes(filter.toLowerCase());
};

export const toLocaleAmount = (value: number | undefined): string => {
  return value != null ? Number(value).toLocaleString(navigator.languages[0]) : '';
};

function offsetToIsoString(offset: number): string {
  const sign: string = offset < 0 ? '-' : '+';
  const hours = Math.floor(Math.abs(offset) / 60);
  const minutes = Math.abs(offset) % 60;
  return `${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}

export function toISOStringWithTimezone(date: Date): string {
  return date.toISOString().replace('Z', offsetToIsoString(date.getTimezoneOffset()));
}

export function isDevOrQAEnvironment(): boolean {
  return APP_ENV === 'DEV' || APP_ENV === 'QA';
}
